import React from 'react'
import { Link } from 'react-scroll';

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';

import styles from './Healthcare.module.scss';

import WhoWeAre from '../IndexPage/sections/WhoWeAre';
import Partners from '../IndexPage/sections/Partners';
import Testimonials from '../IndexPage/sections/Testimonials';

import walking from '../../images/walking_6.png';
import focus from '../../images/focus-areas.jpg';

const Healthcare = () => {
  return (
    <PageLayout>
      <Seo title="Cursor Insight | Healthcare" />
      <div className={styles.Healthcare}>
        <Section
          title="Healthcare Solutions"
          className="pt-lg-5"
        >
          <div className="container">
            <div className="row mb-5 pb-5">
              <div className="col-12 text-center">
                <div className={styles.healthIntro}>
                  <p>
                    Automate Parkinson's &amp; Alzheimer's assessments in
                    healthcare. Measure the effectiveness of medical treatments
                    sooner. Use our e-signature solution for prescriptions and
                    enhance your cybersecurity to stay HIPAA compliant.
                  </p>
                  <p className="py-3">
                    <Link
                      className="mainlink"
                      to="ContactForm"
                      smooth="true"
                    >
                      Get in touch
                    </Link>
                    {' '}
                    if you want to learn more.
                  </p>
                  <a
                    href="https://calendly.com/tamas-cursor-insight/consultation"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimaryLg"
                  >
                    Book a meeting
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-5">
                <div className="business-card motion">
                  <div>
                    <h3>Parkinson’s and Alzheimer’s Assessment</h3>
                    <p>
                      We automated standard tests, such as UPDRS to gain more
                      objective and granular information about patients. Not
                      only will you save time, but you will have deeper
                      insights into the progression of your patients'
                      conditions.
                    </p>
                    <ul>
                      <li>
                        No need for special equipment or wearable markers
                      </li>
                      <li>
                        Objective, quantitative and comparable data
                      </li>
                      <li>
                        Can be used easily at home
                      </li>
                    </ul>
                    <a
                      href="https://www.motionanalysis.health"
                      rel="noreferrer"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/o_UZ8g2JIxA?si=KhicsVXJGgOGmLhO"
                        title="Cursor Insight"
                        frameborder="0"
                        allow="accelerometer; picture-in-picture; web-share"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card sentinel">
                  <div>
                    <h3>Treatment feedback</h3>
                    <p>
                      Get feedback on the mental or physical treatments of your
                      patients. Our motion analysis technology can recognize
                      the smallest changes in human motion and determine how
                      the therapy impacted the patient.
                    </p>
                    <p>Get feedback on:</p>
                    <ul>
                      <li>
                        Medication or drug treatment
                      </li>
                      <li>
                        Mental therapy
                      </li>
                      <li>
                        Physiotherapy
                      </li>
                    </ul>
                    <a
                      href="https://www.motionanalysis.health"
                      rel="noreferrer"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <img src={focus} alr="Walking Recognition" />
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card sw">
                  <div>
                    <h3>Biometric e-Signatures</h3>
                    <p>
                      Digitize document signing with SignoWise, out biometric
                      e-signature solution. The award-winning verification
                      algorithm ensures content integrity and prevents the
                      theft or reuse of a signature that was placed on a
                      document. Customers can start the signing process from
                      MS Word as well and can use any type of tablet to sign
                      the document.
                    </p>
                    <ul>
                      <li>
                        Save up to 15 minutes and EUR 4 per document
                      </li>
                      <li>
                        More than 4 million users
                      </li>
                      <li>
                        Works on iOS, Android, and any tablet or signature
                        device system
                      </li>
                    </ul>
                    <a
                      href="https://signowise.com"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/eENr2K-ujY4?controls=0"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        title="iframe-container"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card sentinel">
                  <div>
                    <h3>Online account takeover prevention</h3>
                    <p>
                      Identify users continuously based on their cursor
                      movement dynamics through browsers and prevent online
                      account takeover fraud with Graboxy Sentinel Online.
                      Unlike most types of biometrics, mouse movement
                      characteristics cannot be stolen, shared, or successfully
                      replicated. Maximize the safety of your customers during
                      the whole user session while keeping the UX frictionless.
                    </p>
                    <ul>
                      <li>
                        Works in browsers
                      </li>
                      <li>
                        Invisible for users
                      </li>
                      <li>
                        Seamless integration
                      </li>
                    </ul>
                    <a
                      href="https://graboxy.com"
                      rel="noreferrer"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/h5biJ-D959o?controls=0"
                        title="Cursor Insight"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card twofa">
                  <div>
                    <h3>Workforce authentication</h3>
                    <p>
                      Remote work increases the risk of corporate account
                      takeovers and data breaches. Authenticate employees
                      continuously in the background based on their cursor
                      movement dynamics. These kinds of cursor biometrics
                      cannot be stolen or replicated. Reach your Zero Trust
                      goals with Graboxy Sentinel Desktop.
                    </p>
                    <ul>
                      <li>
                        Seamless integration
                      </li>
                      <li>
                        NIS2 &amp; SOC2 compliant
                      </li>
                      <li>
                        Invisible for users
                      </li>
                    </ul>
                    <a
                      href="https://graboxy.com"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/DcanTk-cCps?controls=0"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        allowfullscreen
                        title="iframe-container"
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card captcha">
                  <div>
                    <h3>Multi-factor authentication</h3>
                    <p>
                      Authenticate users invisibly in the background of the
                      user session with Graboxy Sentinel or use an optional
                      5-second movement challenge with Graboxy 2FA. No need to
                      send costly SMS codes for the users to switch between
                      devices and apps. We turn cursor movements into biometric
                      profiles that cannot be stolen or imitated.
                    </p>
                    <ul>
                      <li>
                        No need for additional hardware
                      </li>
                      <li>
                        Easily brandable design
                      </li>
                      <li>
                        Cost efficient
                      </li>
                      <li>
                        PSD2 and SCA compliant
                      </li>
                    </ul>
                    <a
                      href="https://graboxy.com"
                      rel="noreferrer"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/Hog8cVGuQY0?controls=0"
                        title="Cursor Insight"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card ci">
                  <div>
                    <h3>Sports medicine</h3>
                    <p>
                      We discover poor technique or injuries by identifying the
                      position and movement of individual body parts by
                      analyzing video footage and without the need of wearable
                      sensors.
                    </p>
                    <ul>
                      <li>
                        Sensorless, no need to invest in expensive devices
                      </li>
                      <li>
                        Prevent sport injuries by identifying improper
                        technique or use of equipment
                      </li>
                      <li>
                        Help training athletes performing complex movements
                      </li>
                    </ul>
                    <a
                      href="https://www.cursorinsight.com/sensor-video-analysis"
                      rel="noreferrer"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <img src={walking} alr="Walking Recognition" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <WhoWeAre />
        <Partners />
        <Testimonials />
      </div>
    </PageLayout>
  );
}

export default Healthcare;
