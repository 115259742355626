import React from 'react'

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';

import styles from './DetectingDementia.module.scss';

import WhoWeAre from '../IndexPage/sections/WhoWeAre';
import Testimonials from '../IndexPage/sections/Testimonials';

import icon from './icon.png';
import piktogram from './piktogram.png';
import img1 from './img1.png';
import mahealth from './mahealth.png';
import icon2 from './icon2.png';
import icon3 from './icon3.png';

const DetectingDementia = () => {
  return (
    <PageLayout>
      <Seo title="Cursor Insight | Detecting Dementia" />
      <div className={styles.DetectingDementia}>
        <Section
          title="Detecting Dementia by Analyzing Cursor Movement Patterns"
        >
          <div className="container">
            <div className="row mb-5 pb-5">
              <div className="col-12 text-center">
                <div className={styles.Intro}>
                  <div className={styles.Card}>
                    <img src={icon} alt="icon" />
                  </div>
                  <p><strong>Partner:</strong> Hungarian National Institute of
                  Clinical Neurosciences
                  </p>
                  <p>
                    <strong>Goal:</strong> A cost-efficient and easy-to-use
                    screening method for Mild Cognitive Impairment detection
                  </p>
                  <p>
                    <strong>Solution:</strong>
                    {' '}
                    <a
                      href="https://www.motionanalysis.health/"
                      target="_blank"
                    >
                      MotionAnalysis.Health
                    </a>
                  </p>
                  <a
                    href="https://calendly.com/tamas-cursor-insight/consultation
                    "
                    target="_blank"
                    className="ButtonPrimary mt-3"
                  >
                    Schedule a call
                  </a>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <h2>Challenge</h2>
                  <p>
                    MCI has a 10%-20% prevalence in individuals aged 65 and
                    above.
                    <br />
                    Early detection is crucial as it enables the implementation
                    of preventative measures to delay severe symptoms. However,
                    diagnosing MCI currently relies on time-consuming cognitive,
                    neurological, and laboratory tests, making widespread
                    screening impractical. The challenge lies in developing
                    more efficient, non-intrusive methods that don't demand
                    extensive time and high-level medical expertise, allowing
                    broader screening for the elderly population.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <img src={piktogram} />
                </div>
              </div>
            </div>
            <div className={`row mb-5 ${styles.Reverse}`}>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <img src={img1} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <h2>Solution</h2>
                  <p>
                    We have developed a machine learning model that demonstrates
                    effective MCI prediction based on just a few seconds of
                    computer mouse movement. To validate the efficacy of our
                    approach, we collaborated with the National Institute of
                    Mental Health, Neurology, and Neurosurgery in Budapest,
                    Hungary, conducting a research study to assess the
                    performance of our proposed method.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <div
                  className="d-flex justify-content-between flex-column
                  flex-md-row"
                >
                  <div className={styles.Icon}>
                    <a
                      href="https://www.sciencedirect.com/science/article/pii/
                      S235291482200257X"
                      target="_blank"
                    >
                      <img src={icon2} alt="icon2" width={250} />
                    </a>
                  </div>
                  <div className={styles.Icon}>
                    <a
                      href="https://www.motionanalysis.health/"
                      target="_blank"
                    >
                      <img src={mahealth} alt="mahealth" width={500} />
                    </a>
                  </div>
                  <div className={styles.Icon}>
                    <img src={icon3} alt="icon3" width={200} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className={styles.Results}>
                <h2>Results</h2>
                <p className="mt-5">
                  MCI prediction based on just a few seconds of computer mouse
                  movement.
                </p>
                <div className="row mt-5">
                  <div className="col-lg-4">
                    <h3>79.8%</h3>
                    <p>Average accuracy</p>
                  </div>
                  <div className="col-lg-4">
                    <h3>83.6%</h3>
                    <p>
                      of people who are diagnosed with MCI are correctly
                      classified
                    </p>
                  </div>
                  <div className="col-lg-4">
                    <h3>76%</h3>
                    <p>
                      of people with no diagnosed MCI are correctly classified
                    </p>
                  </div>
                </div>
                <a
                  href="https://calendly.com/tamas-cursor-insight/consultation
                    "
                  target="_blank"
                  className="ButtonPrimary mt-3"
                >
                  Schedule a call
                </a>
              </div>
            </div>
          </div>
        </Section>
        <Testimonials />
        <WhoWeAre />
      </div>
    </PageLayout>
  );
}

export default DetectingDementia;
