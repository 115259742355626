import React from 'react'
import { Tab, Nav } from 'react-bootstrap';

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';

import './NurologiqDemoPage.scss';

import gabor3 from '../../videos/3.4-gabor-1.mov';
import gabor4 from '../../videos/3.4-gabor-4.mov';
import gabor1 from '../../videos/3.9-gabor-1.mov';
import gabor2 from '../../videos/3.9-gabor-2.mov';
import julcsi1 from '../../videos/3.15-julcsi-2.mov';
import julcsi2 from '../../videos/3.15-julcsi-3.mov';

const NurologiqDemoPage = () => (
  <PageLayout>
    <Seo title="Cursor Insight | Nurologiq Demo" />
    <Section
      title="UPDRS Assessment Demo"
      className="Nurology"
    >
      <h3 className="text-center pb-5">
        The only validated feature extractor accurately describing human motion.
        <br />
        Perfected for 15 years and used by millions today..
      </h3>
      <div className="d-flex mb-5">
        <a
          href="https://calendly.com/tamas-cursor-insight/consultation"
          rel="noreferrer"
          target="_blank"
          className="ButtonPrimaryLg"
        >
          Book a meeting
        </a>
      </div>
      <Tab.Container defaultActiveKey="one">
        <div className="container">
          <div className="row mx-auto">
            <div className="col-12">
              <Nav variant="pills" className="flex-row justify-content-center">
                <Nav.Item className="TabItem">
                  <Nav.Link eventKey="one">
                    Finger tapping 1
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="TabItem">
                  <Nav.Link eventKey="two">
                    Finger tapping 2
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="TabItem">
                  <Nav.Link eventKey="three">
                    Arising from chair 1
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="TabItem">
                  <Nav.Link eventKey="four">
                    Arising from chair 2
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="TabItem">
                  <Nav.Link eventKey="five">
                    Postural tremor of the hands 1
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="TabItem">
                  <Nav.Link eventKey="six">
                    Postural tremor of the hands 2
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="one">
                  <section className="text-center mt-5">
                    <h1>Finger tapping 1</h1>
                    <h4>2022 May 5 18:29</h4>
                    <div className="row mt-5 patient-container">
                      <div className="col-12 col-lg-5 video-container">
                        <video controls autoPlay>
                          <source src={gabor4} type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-12 col-lg-7">
                        <table className="metadata">
                          <tr>
                            <th>Patient name</th>
                            <td>Jane / John Doe</td>
                            <th>Assessor name</th>
                            <td>Dr. John Doe</td>
                          </tr>
                          <tr>
                            <th>Birth date</th>
                            <td>1971-10-29</td>
                            <th>Assessment location</th>
                            <td>St. Stephen Hospital</td>
                          </tr>
                          <tr>
                            <th>Social Security Number</th>
                            <td>12345-6789</td>
                            <th>Assessment date</th>
                            <td>2021-10-29</td>
                          </tr>
                        </table>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Estimated UPDRS score</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Left</th>
                              <td>0</td>
                            </tr>
                            <tr>
                              <th scope="row">Right</th>
                              <td>0</td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Mean repetition duration &#40;ms&#41;</th>
                              <th scope="col">Amplitude decrement at repetition</th>
                              <th scope="col">Hesitation count</th>
                              <th scope="col">Freeze count</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Left</th>
                              <td>300.0</td>
                              <td>10</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <th scope="row">Right</th>
                              <td>306.78</td>
                              <td>None</td>
                              <td>1</td>
                              <td>0</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-flex mt-5">
                          <a
                            href="https://www.motionanalysis.health"
                            target="_blank"
                            rel="noreferrer"
                            className="ButtonPrimaryLg"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="images maxwidth">
                          <iframe
                            title="Finger tapping"
                            src="3.4-gabor-4.html">
                          </iframe>
                        </div>
                      </div>
                    </div>
                  </section>
                </Tab.Pane>
                <Tab.Pane eventKey="two">
                  <section className="text-center mt-5">
                    <h1>Finger tapping 2</h1>
                    <h4>2022 May 5 18:29</h4>
                    <div className="row mt-5 patient-container">
                      <div className="col-12 col-lg-5 video-container">
                        <video controls autoPlay>
                          <source src={gabor3} type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-12 col-lg-7">
                        <table class="metadata">
                          <tr>
                            <th>Patient name</th>
                            <td>Jane / John Doe</td>
                            <th>Assessor name</th>
                            <td>Dr. John Doe</td>
                          </tr>
                          <tr>
                            <th>Birth date</th>
                            <td>1971-10-29</td>
                            <th>Assessment location</th>
                            <td>St. Stephen Hospital</td>
                          </tr>
                          <tr>
                            <th>Social Security Number</th>
                            <td>12345-6789</td>
                            <th>Assessment date</th>
                            <td>2021-10-29</td>
                          </tr>
                        </table>
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Estimated UPDRS score</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Left</th>
                              <td>1</td>
                            </tr>
                            <tr>
                              <th scope="row">Right</th>
                              <td>3</td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Mean repetition duration &#40;ms&#41;</th>
                              <th scope="col">Amplitude decrement at repetition</th>
                              <th scope="col">Hesitation count</th>
                              <th scope="col">Freeze count</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Left</th>
                              <td>563.94</td>
                              <td>7</td>
                              <td>5</td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <th scope="row">Right</th>
                              <td>406.78</td>
                              <td>6</td>
                              <td>1</td>
                              <td>1</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-flex mt-5">
                          <a
                            href="https://www.motionanalysis.health"
                            target="_blank"
                            rel="noreferrer"
                            className="ButtonPrimaryLg"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="images maxwidth">
                          <iframe
                            title="Finger tapping"
                            src="3.4-gabor-1.html">
                          </iframe>
                        </div>
                      </div>
                    </div>
                  </section>
                </Tab.Pane>
                <Tab.Pane eventKey="three">
                  <section className="text-center mt-5">
                    <h1>Arising from chair 1</h1>
                    <h4>2022 May 5 18:30</h4>
                    <div className="row mt-5 patient-container">
                      <div className="col-12 col-lg-5 video-container">
                        <video controls autoPlay>
                          <source src={gabor2} type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-12 col-lg-7">
                        <table className="metadata w-100">
                          <tr>
                            <th>Patient name</th>
                            <td>Jane / John Doe</td>
                            <th>Assessor name</th>
                            <td>Dr. John Doe</td>
                          </tr>
                          <tr>
                            <th>Birth date</th>
                            <td>1971-10-29</td>
                            <th>Assessment location</th>
                            <td>St. Stephen Hospital</td>
                          </tr>
                          <tr>
                            <th>Social Security Number</th>
                            <td>12345-6789</td>
                            <th>Assessment date</th>
                            <td>2021-10-29</td>
                          </tr>
                        </table>
                        <div className="markdown">
                          <hr />
                          <h3>
                            <strong>Estimated UPDRS score: 0</strong>
                          </h3>
                          <hr />
                        </div>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Failed attempts</th>
                              <th scope="col">Number of hand assist</th>
                              <th scope="col">Moved forward</th>
                              <th scope="col">Used hand to push off</th>
                              <th scope="col">Help needed to arise</th>
                              <th scope="col">Successful attempts</th>
                              <th scope="col">Successful attempt duration &#40;ms&#41;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row"></th>
                              <td>2</td>
                              <td>0</td>
                              <td>no</td>
                              <td>no</td>
                              <td>no</td>
                              <td>1</td>
                              <td>1203.39</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-flex mt-5">
                          <a
                            href="https://www.motionanalysis.health"
                            target="_blank"
                            rel="noreferrer"
                            className="ButtonPrimaryLg"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="images maxwidth">
                          <iframe
                            title="Finger tapping"
                            src="3.9-gabor-2.html">
                          </iframe>
                        </div>
                      </div>
                    </div>
                  </section>
                </Tab.Pane>
                <Tab.Pane eventKey="four">
                  <section className="text-center mt-5">
                    <h1>Arising from chair 2</h1>
                    <h4>2022 May 5 18:29</h4>
                    <div className="row mt-5 patient-container">
                      <div className="col-12 col-lg-5 video-container">
                        <video controls autoPlay>
                          <source src={gabor1} type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-12 col-lg-7">
                        <table className="metadata w-100">
                          <tr>
                            <th>Patient name</th>
                            <td>Jane / John Doe</td>

                            <th>Assessor name</th>
                            <td>Dr. John Doe</td>
                          </tr>
                          <tr>
                            <th>Birth date</th>
                            <td>1971-10-29</td>

                            <th>Assessment location</th>
                            <td>St. Stephen Hospital</td>
                          </tr>
                          <tr>
                            <th>Social Security Number</th>
                            <td>12345-6789</td>

                            <th>Assessment date</th>
                            <td>2021-10-29</td>
                          </tr>
                        </table>
                        <div className="markdown">
                          <hr />
                          <h3>
                            <strong>Estimated UPDRS score: 2</strong>
                          </h3>
                          <hr />
                        </div>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Failed attempts</th>
                              <th scope="col">Number of hand assist</th>
                              <th scope="col">Moved forward</th>
                              <th scope="col">Used hand to push off</th>
                              <th scope="col">Help needed to arise</th>
                              <th scope="col">Successful attempts</th>
                              <th scope="col">Successful attempt duration &#40;ms&#41;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row"></th>
                              <td>0</td>
                              <td>1</td>
                              <td>no</td>
                              <td>yes</td>
                              <td>no</td>
                              <td>1</td>
                              <td>661.02</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-flex mt-5">
                          <a
                            href="https://www.motionanalysis.health"
                            target="_blank"
                            rel="noreferrer"
                            className="ButtonPrimaryLg"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="images maxwidth">
                          <iframe
                            title="Finger tapping"
                            src="3.9-gabor-1.html">
                          </iframe>
                        </div>
                      </div>
                    </div>
                  </section>
                </Tab.Pane>
                <Tab.Pane eventKey="five">
                  <section className="text-center mt-5">
                    <h1>Postural tremor of the hands 1</h1>
                    <h4>2022 May 5 16:24</h4>
                    <div className="row mt-5 patient-container">
                      <div className="col-12 col-lg-5 video-container">
                        <video controls autoPlay>
                          <source src={julcsi2} type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-12 col-lg-7">
                        <table className="metadata w-100">
                          <tr>
                            <th>Patient name</th>
                            <td>Jane / John Doe</td>
                            <th>Assessor name</th>
                            <td>Dr. John Doe</td>
                          </tr>
                          <tr>
                            <th>Birth date</th>
                            <td>1971-10-29</td>
                            <th>Assessment location</th>
                            <td>St. Stephen Hospital</td>
                          </tr>
                          <tr>
                            <th>Social Security Number</th>
                            <td>12345-6789</td>
                            <th>Assessment date</th>
                            <td>2021-10-29</td>
                          </tr>
                        </table>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Estimated UPDRS score</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Left</th>
                              <td>0</td>
                            </tr>
                            <tr>
                              <th scope="row">Right</th>
                              <td>0</td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Maximum detected amplitude &#40;cm&#41;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Left</th>
                              <td>0.17</td>
                            </tr>
                            <tr>
                              <th scope="row">Right</th>
                              <td>0.24</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-flex mt-5">
                          <a
                            href="https://www.motionanalysis.health"
                            target="_blank"
                            rel="noreferrer"
                            className="ButtonPrimaryLg"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="images">
                          <iframe
                            title="Finger tapping"
                            src="3.15-julcsi-2.html">
                          </iframe>
                        </div>
                      </div>
                    </div>
                  </section>
                </Tab.Pane>
                <Tab.Pane eventKey="six">
                  <section className="text-center mt-5">
                    <h1>Postural tremor of the hands 2</h1>
                    <h4>2022 May 5 16:24</h4>
                    <div className="row mt-5 patient-container">
                      <div className="col-12 col-lg-5 video-container">
                        <video controls autoPlay>
                          <source src={julcsi1} type="video/mp4" />
                        </video>
                      </div>
                      <div className="col-12 col-lg-7">
                        <table className="metadata w-100">
                          <tr>
                            <th>Patient name</th>
                            <td>Jane / John Doe</td>
                            <th>Assessor name</th>
                            <td>Dr. John Doe</td>
                          </tr>
                          <tr>
                            <th>Birth date</th>
                            <td>1971-10-29</td>
                            <th>Assessment location</th>
                            <td>St. Stephen Hospital</td>
                          </tr>
                          <tr>
                            <th>Social Security Number</th>
                            <td>12345-6789</td>
                            <th>Assessment date</th>
                            <td>2021-10-29</td>
                          </tr>
                        </table>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Estimated UPDRS score</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Left</th>
                              <td>2</td>
                            </tr>
                            <tr>
                              <th scope="row">Right</th>
                              <td>2</td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Maximum detected amplitude &#40;cm&#41;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Left</th>
                              <td>2.29</td>
                            </tr>
                            <tr>
                              <th scope="row">Right</th>
                              <td>2.84</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-flex mt-5">
                          <a
                            href="https://www.motionanalysis.health"
                            target="_blank"
                            rel="noreferrer"
                            className="ButtonPrimaryLg"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="images">
                          <iframe
                            title="Finger tapping"
                            src="3.15-julcsi-3.html">
                          </iframe>
                        </div>
                      </div>
                    </div>
                  </section>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </div>
      </Tab.Container>
    </Section>
  </PageLayout>
);

export default NurologiqDemoPage;
