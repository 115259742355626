import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import IndexPage from './pages/IndexPage';
import SensorAndVideoAnalysisPage from './pages/SensorAndVideoAnalysis';
import ContactPage from './pages/Contact';
import OurTeamPage from './pages/OurTeam';
import UseCasesPage from './pages/UseCases';
import Technology from './pages/Technology';
import Solutions from './pages/Solutions';
import Resources from './pages/Resources';
import ForensicAnalysisPage from './pages/ForensicAnalysisPage';
import NurologiqDemoPage from './pages/NurologiqDemoPage';
import WalkingRecognitionPage from './pages/WalkingRecognition';
import ContinousAuthentication from './pages/ContinousAuthentication';
import BiometricEsignatureBanking from './pages/BiometricEsignatureBanking';
import DroneDetection from './pages/DroneDetection';
import DetectingDementia from './pages/DetectingDementia';
import ClinicalNeurosciences from './pages/ClinicalNeurosciences';
import BankingSolutions from './pages/BankingSolutions';
import Cybersecurity from './pages/Cybersecurity';
import LawEnforcement from './pages/LawEnforcement';
import Government from './pages/Government';
import Healthcare from './pages/Healthcare';
import Gaming from './pages/Gaming';
import Gdpr from './pages/Gdpr';
import BlogPage from './pages/Blog';
import TagRedirect from './pages/Blog/components/TagRedirect';
import PostPage from './pages/Blog/PostPage';
import DocumentPage from './pages/Document';
import CookieConsent from './components/CookieConsent';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={IndexPage} />
      <Route exact path="/use-cases" component={UseCasesPage} />
      <Route exact path="/technology" component={Technology} />
      <Route exact path="/solutions" component={Solutions} />
      <Route exact path="/resources" component={Resources} />

      <Route
        exact
        path="/forensic-analysis"
        component={ForensicAnalysisPage}
      />

      <Route
        exact
        path="/updrs-assessment-demo"
        component={NurologiqDemoPage}
      />

      <Route
        exact
        path="/walking-recognition"
        component={WalkingRecognitionPage}
      />

      <Route
        exact
        path="/sensor-video-analysis"
        component={SensorAndVideoAnalysisPage}
      />

      <Route
        exact
        path="/casestudy/online-user-authentication-banking"
        component={ContinousAuthentication}
      />

      <Route
        exact
        path="/casestudy/biometric-esignature-banking"
        component={BiometricEsignatureBanking}
      />

      <Route
        exact
        path="/casestudy/drone-detection"
        component={DroneDetection}
      />

      <Route
        exact
        path="/casestudy/detecting-dementia"
        component={DetectingDementia}
      />

      <Route
        exact
        path="/casestudy/clinical-neurosciences"
        component={ClinicalNeurosciences}
      />

      <Route
        exact
        path="/banking-solutions"
        component={BankingSolutions}
      />

      <Route
        exact
        path="/law-enforcement"
        component={LawEnforcement}
      />

      <Route
        exact
        path="/government"
        component={Government}
      />

      <Route
        exact
        path="/healthcare"
        component={Healthcare}
      />

      <Route
        exact
        path="/cybersecurity"
        component={Cybersecurity}
      />

      <Route
        exact
        path="/gaming"
        component={Gaming}
      />

      <Route exact path="/contact" component={ContactPage} />
      <Route exact path="/our-team" component={OurTeamPage} />
      <Route exact path="/our-team/gdpr" component={Gdpr} />
      <Redirect exact from="/blog/tag" to="/blog/1" />
      <Redirect exact from="/blog" to="/blog/1" />
      <Route exact path="/blog/:page" component={BlogPage} />

      <Route
        exact
        path="/blog/tag/:tag"
        component={TagRedirect}
      />

      <Route exact path="/post/:postId/:slug" component={PostPage} />

      <Route exact path="/document/:documentSlug" component={DocumentPage} />
      <Route path="**" component={IndexPage} />
    </Switch>
    <CookieConsent />
  </BrowserRouter>
);

export default App;
