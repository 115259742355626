import React from 'react'

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';

import RealTimeVideo from '../../components/RealTimeVideo';
import BlogTeaser from '../Blog/components/BlogTeaser';

import styles from './ForensicAnalysisPage.module.scss';

import walk1 from '../../images/walking1.png';
import walk2 from '../../images/walking2.png';
import walk3 from '../../images/walking3.png';
import walk4 from '../../images/walking4.png';

import walking1 from '../../images/walking-behavior.svg';
import walking2 from '../../images/walking-unauthorized.svg';
import walking3 from '../../images/walking-accidents.svg';

import utilize1 from '../../images/utilize-one.svg';
import utilize2 from '../../images/utilize-two.svg';
import utilize3 from '../../images/utilize-three.svg';

const ForensicAnalysisPage = () => (
  <div className={styles.ForensicAnalysis}>
    <PageLayout>
      <Seo title="Cursor Insight | Forensic Analysis" />
      <Section
        title="Forensic Analysis with Walking Recognition"
      >
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className={styles.videoContainer}>
              <iframe
                title="Walking Recognition"
                src="https://player.vimeo.com/video/922004834?h=e589af612e"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              >
              </iframe>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-lg-5">
            <h3>
              Identify individuals in crowds
              {' '}
              <span>without face recognition</span>
              {' '}
              from their gross motor coordination.
            </h3>
            <h3>
              Protect personal data by not processing or storing sensitive
              biometric features.
            </h3>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 offset-lg-2">
            <div className={styles.WalkingImageItem}>
              <div className={styles.WalkingImageItemImg}>
                <img src={walk1} alt="walking1" />
              </div>
              <div className={styles.WalkingImageItemText}>
                <div>
                  <p>
                    Our gait recognition system's AI algorithm detects walking
                    patterns which are like fingerprints
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.WalkingImageItem}>
              <div className={styles.WalkingImageItemImg}>
                <img src={walk2} alt="walking2" />
              </div>
              <div className={styles.WalkingImageItemText}>
                <div>
                  <p>
                    Walking Recognition can identify individuals in crowds and
                    open spaces
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.WalkingImageItem}>
              <div className={styles.WalkingImageItemImg}>
                <img src={walk3} alt="walking3" />
              </div>
              <div className={styles.WalkingImageItemText}>
                <div>
                  <p>
                    Our technology can recognize people wearing masks or
                    appearing on low-res videos
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.WalkingImageItem}>
              <div className={styles.WalkingImageItemImg}>
                <img src={walk4} alt="walking2" />
              </div>
              <div className={styles.WalkingImageItemText}>
                <div>
                  <p>
                    Use your existing CCTV setup or an autonomous drone
                    monitoring system
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section className={styles.Utilize}>
        <div className="row">
          <div className="col-lg-4 text-center">
            <div className={styles.Box}>
              <h4>Improve Investigation Efficiency</h4>
              <div>
                <img src={utilize1} width={80} className="my-4" />
              </div>
              <p>
                Our gait analysis system enables quick suspect recognition,
                speeds up crime solving and increases the accuracy of suspect
                identification.
              </p>
            </div>
          </div>
          <div className="col-lg-4 text-center">
            <div className={styles.Box}>
              <h4>Enrich CCTV Footage</h4>
              <div>
                <img src={utilize2} width={80} className="my-4" />
              </div>
              <p>
                Walking Recognition is designed to generate biometric profiles
                by analyzing existing CCTV footage and video records.
              </p>
            </div>
          </div>
          <div className="col-lg-4 text-center">
            <div className={styles.Box}>
              <h4>Enhance Identification Methods</h4>
              <div>
                <img src={utilize3} width={80} className="my-4" />
              </div>
              <p>
                Gait recognition complements or replaces the most common
                biometric identification methods in forensic science, such as
                DNA, fingerprint, facial, and iris recognition.
              </p>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            <p
              style={{ color: 'white', fontSize: '20px', textAlign: 'center' }}
            >
              <a href="/sensor-video-analysis" className="mainlink">
                Learn more
              </a>
              {' '}
              about how we can help to avoid injuries and accidents by
              analyzing video or sensor movement data. Visit the
              {' '}
              <a
                href="https://www.motionanalysis.health"
                target="_blank"
                className="mainlink"
              >
                Motion Analysis website
              </a>
              {' '}
              to see how we deploy video-based movement analysis to assist
              clinical research of Parkinson’s disease and other
              neurodegenerative conditions.
            </p>
          </div>
        </div>
      </Section>
      <RealTimeVideo />
      <Section>
        <div className="row mt-5 mt-lg-0">
          <div className="col-md-8 offset-md-2 my-3">
            <h3>
              Enhance your facility’s physical security with our adaptive AI
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <ul className={styles.List}>
              <li>
                <div>
                  <img src={walking1} alt="" />
                </div>
                <span>
                  Identify suspicious behavior, such as signs of nervousness or
                  hostility in airports, banks and other public spaces
                </span>
              </li>
              <li>
                <div>
                  <img src={walking2} alt="" height={110} />
                </div>
                <span>
                  Flag unauthorized use of NFC/RFID cards or tailgating at
                  access control points in offices and other facilities
                </span>
              </li>
              <li>
                <div>
                  <img src={walking3} alt="" height={140} />
                </div>
                <span>
                  Detect accidents or people in distress in industrial or
                  sport facilities
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Section>
      <BlogTeaser />
      <Section className={styles.Faq}>
        <div className="row">
          <div className="col-12 mt-5">
            <h1>FAQ</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-5 pt-3">
            <div className="accordion">
              <div className={styles.AccordionItem}>
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                    aria-expanded="false" aria-controls="collapseOne">
                    What hardware is needed?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse"
                  aria-labelledby="headingOne">
                  <div className="accordion-body">
                    Modern CPU with 10+ cores and 3+ GHz clock speed
                    <br />
                    Nvidia GPU with 12+ GB VRAM, such as RTX 3060 or better
                    <br />
                    16+ GB RAM
                  </div>
                </div>
              </div>
              <div className={styles.AccordionItem}>
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                    aria-expanded="false" aria-controls="collapseTwo">
                    Is any internet connection needed?
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse"
                  aria-labelledby="headingTwo">
                  <div className="accordion-body">
                    If all data is available in order for our software to run
                    without accessing the internet, then it is not needed. It
                    can run on prem without any data leaving your servers.
                  </div>
                </div>
              </div>
              <div className={styles.AccordionItem}>
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseThree"
                    aria-expanded="false" aria-controls="collapseThree">
                    How many persons can be analysed at the same time?
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                >
                  <div className="accordion-body">
                    This depends on many variables but it is safe to say
                    between 6-10 individuals.
                  </div>
                </div>
              </div>
              <div className={styles.AccordionItem}>
                <h2 className="accordion-header" id="headingFour">
                  <button className="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseFour"
                    aria-expanded="false" aria-controls="collapseFour">
                    What is a typical scenario?
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                >
                  <div className="accordion-body">
                    There is a reference/training video that shows the target
                    person walking or standing somewhere. The target person
                    must be manually labeled in the video. The program then
                    analyzes the other videos and gives each person a score
                    based on how much they resemble the target person. This
                    allows you to search for the target person in the videos.
                  </div>
                </div>
              </div>
              <div className={styles.AccordionItem}>
                <h2 className="accordion-header" id="headingFive">
                  <button className="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseFive"
                    aria-expanded="false" aria-controls="collapseFour">
                    Does the software provide alarm feature?
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                >
                  <div className="accordion-body">
                    This part is 100% configurable to your needs!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </PageLayout>
  </div>
);

export default ForensicAnalysisPage;
