import { Link } from 'react-scroll';

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import PageTitle from '../../components/PageTitle';
import BlogTeaser from '../Blog/components/BlogTeaser';
import Seo from '../../components/Seo';
import styles from './SensorVideo.module.scss';

import walking1 from '../../images/walking_1.png';
import walking2 from '../../images/walking_2.png';
import walking3 from '../../images/walking_3.png';
import walking4 from '../../images/walking_4.png';
import walking5 from '../../images/walking_5.png';
import walking6 from '../../images/walking_6.png';

const SensorAndVideoAnalysisPage = () => (
  <PageLayout>
    <Seo title="Cursor Insight | 3D Movement Analysis" />
    <Section className={styles.SensorVideo}>
      <PageTitle>3D Movement Analysis</PageTitle>
      <div className="row">
        <div className="col-lg-8 offset-lg-2 my-3">
          <p style={{ color: 'white', fontSize: '20px', textAlign: 'center' }}>
            Hand gestures, eye and body movements often reveal more information
            than spoken words. Cursor Insight’s world-class movement analysis
            technology works with data from videos and all kinds of movement
            sensors, including the ones built in mobile phones. With our
            in-house developed programming language and AI toolchain, we are
            able to mathematically describe motion and classify movement
            samples.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className={styles.videoContainer}>
            <iframe
              title="Walking Recognition"
              src="https://player.vimeo.com/video/883181847?h=729aaec57b"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            >
            </iframe>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 offset-lg-2 my-3">
          <p style={{ color: 'white', fontSize: '20px', textAlign: 'center' }}>
            In our <strong>Rowerwatch</strong> application, we use the
            gyroscope data of an on-board Android phone to monitor the safe
            movement of a rocking kayak, canoe or rowing boat.
          </p>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-lg-8 offset-lg-2">
          <div className={styles.MovementSensorItem}>
            <div className={styles.MovementSensorItemImg}>
              <img src={walking1} alt="walking1" />
            </div>
            <div className={styles.MovementSensorItemText}>
              <div>
                <p>
                  The phone’s gyroscope data, representing the rocking of the
                  boat, is processed in real time
                </p>
              </div>
            </div>
          </div>
          <div className={styles.MovementSensorItem}>
            <div className={styles.MovementSensorItemImg}>
              <img src={walking2} alt="walking2" />
            </div>
            <div className={styles.MovementSensorItemText}>
              <div>
                <p>
                  When the algorithm detects a capsize or a hard collision, it
                  immediately notifies an emergency contact
                </p>
              </div>
            </div>
          </div>
          <div className={styles.MovementSensorItem}>
            <div className={styles.MovementSensorItemImg}>
              <img src={walking3} alt="walking3" />
            </div>
            <div className={styles.MovementSensorItemText}>
              <div>
                <p>
                  A coach or an emergency response team receives the alert
                  notification together with the Google map coordinates of the
                  incident
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-12">
          <div className={styles.GetInTouch}>
            <div className={styles.GetInTouchInner}>
              <h3>
                Where to use sensor and video movement analysis?
              </h3>
              <Link to="ContactForm" className="ButtonPrimary">
                Get in touch
              </Link>
            </div>
            <div className={styles.GetInTouchInner}>
              <ul>
                <li>
                  Help training athletes performing complex movements
                </li>
                <li>
                  Prevent sport injuries by identifying improper technique or
                  use of equipment
                </li>
                <li>
                  Detect the dangerous movement of boats or other vehicles to
                  identify or avoid accidents
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <p style={{ color: 'white', fontSize: '20px', textAlign: 'center' }}>
            By analysing a video footage, we are able to identify the position
            and movement of individual body parts without the need of wearable
            sensors or markers. Our <strong>Fitness Coach</strong> application
            is able to detect poor running styles that may lead to injuries or
            inefficiency.
          </p>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-lg-8 offset-lg-2">
          <div className={styles.MovementSensorItem}>
            <div className={styles.MovementSensorItemImg}>
              <img src={walking4} alt="walking4" />
            </div>
            <div className={styles.MovementSensorItemText}>
              <div>
                <p>
                  The absolute and relative movement of joints are analyzed in
                  real time, without the need to wear sensors or markers
                </p>
              </div>
            </div>
          </div>
          <div className={styles.MovementSensorItem}>
            <div className={styles.MovementSensorItemImg}>
              <img src={walking5} alt="walking5" />
            </div>
            <div className={styles.MovementSensorItemText}>
              <div>
                <p>
                  Our algorithm identifies common running mistakes, such as
                  leaning forward too much, that can lead to spine injuries
                </p>
              </div>
            </div>
          </div>
          <div className={styles.MovementSensorItem}>
            <div className={styles.MovementSensorItemImg}>
              <img src={walking6} alt="walking6" />
            </div>
            <div className={styles.MovementSensorItemText}>
              <div>
                <p>
                  Fitness Coach also spots sub-optimal movements, such as
                  lifting the heels too high, that have a negative impact on
                  the athlete's performance
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 offset-lg-2 mb-5">
          <p style={{ color: 'white', fontSize: '20px', textAlign: 'center' }}>
            <a
              className="mainlink"
              href="/walking-recognition"
              target="_blank"
            >
              Click here
            </a>
            {' '}
            to learn more about how we can recognize people based on
            their walking dynamics, using simple CCTV footage. And
            {' '}
            <a
              className="mainlink"
              href="https://www.motionanalysis.health"
              target="_blank"
            >
              click here
            </a>
            {' '}
            to see how we deploy video based movement analysis to assist
            clinical research of Parkinson’s disease and other
            neurodegenerative conditions.
          </p>
        </div>
      </div>
    </Section>
    <BlogTeaser />
  </PageLayout>
);

export default SensorAndVideoAnalysisPage;
