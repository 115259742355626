import { useCallback, useState } from 'react';

import Text from './Text';
import Hexagon from './Hexagon';
import logoEhealth from './images/white-logo-ehealth.png';
import logoSignoWise from './images/white-logo-signo-wise.png';
import graboxy from './images/graboxy.svg';
import texts from './Texts.module.scss';
import strings from './Strings.module.scss';
import hexagons from './Hexagons.module.scss';

const TEXTS = [
  {
    className: texts.Cybersecurity,
    children: 'Cybersecurity',
  },
  {
    className: texts.Neurology,
    children: 'Video Analysis',
  },
  {
    className: texts.Biometrics,
    children: 'Biometrics',
  },
];

const STRINGS = [
  { className: strings.String1 },
  { className: strings.String2 },
  { className: strings.String3 },
  { className: strings.String4 },
  { className: strings.String5 },

  { className: strings.String6 },
  { className: strings.String7 },

  { className: strings.String8 },
  { className: strings.String9 },
  { className: strings.String10 },
  { className: strings.String11 },
  { className: strings.String12 },
];

const HEXAGONS = [
  {
    idx: 1,
    to: 'https://graboxy.com',
    className: hexagons.Hexagon1,
    title: 'Continuous Authentication',
    image: graboxy,
  },
  {
    idx: 2,
    to: 'https://graboxy.com',
    className: hexagons.Hexagon2,
    title: 'Blocking Spam Traffic',
    image: graboxy,
  },
  {
    idx: 3,
    to: 'https://graboxy.com',
    className: hexagons.Hexagon3,
    title: 'Remote Desktop Detection',
    image: graboxy,
  },
  {
    idx: 4,
    to: 'https://graboxy.com',
    className: hexagons.Hexagon4,
    title: 'Multi-Factor Authentication',
    image: graboxy,
  },
  {
    idx: 5,
    to: '/walking-recognition',
    className: hexagons.Hexagon5,
    title: 'Walking Recognition',
  },
  {
    idx: 6,
    to: 'https://www.motionanalysis.health',
    className: hexagons.Hexagon6,
    title: 'Clinical Diagnostics',
    image: logoEhealth,
  },
  {
    idx: 7,
    to: '/sensor-video-analysis',
    className: hexagons.Hexagon7,
    title: '3D Movement Analysis',
  },
  {
    idx: 8,
    to: 'https://signowise.com',
    className: hexagons.Hexagon8,
    title: 'Biometric<br/>e-Signatures',
    image: logoSignoWise,
  },
];

const Hexagons = () => {
  const [idx, setIdx] = useState(0);

  const onMouse = useCallback((idx) => {
    setIdx(idx);
  }, [setIdx]);

  const renderedStrings = STRINGS.map((string, i) => (
    <div key={i} {...string} />
  ));

  const renderedHexagons = HEXAGONS.map((hexagon) => (
    <Hexagon key={hexagon.idx} {...hexagon} onMouse={onMouse} />
  ));

  const renderedTexts = TEXTS.map(({ className, children }, i) => {
    const classes = [
      className,
      (0 < idx && idx <= 4) && texts.CybersecurityHover,
      (idx === 5) && texts.NeurologyHover,
      (6 <= idx) && texts.BiometricsHover,
    ].join(' ');

    return (
      <Text key={i} className={classes}>
        {children}
      </Text>
    );
  });

  return (
    <div className={hexagons.Hexagons}>
      {renderedTexts}
      {renderedStrings}
      {renderedHexagons}
    </div>
  );
};

export default Hexagons;
